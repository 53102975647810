/*!
 * Start Bootstrap - New Age v5.0.7 (https://startbootstrap.com/template-overviews/new-age)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-new-age/blob/master/LICENSE)
 */

@font-face {
  font-family: "sharepass_regular";
  src: /*url('../fonts/sharepass-webfont.woff2') format('woff2'),
       url('../fonts/sharepass-webfont.woff') format('woff');*/ url("../fonts/SharePass.otf")
    format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "sharepass_regular";
  src: /*url('../fonts/sharepass-webfont.woff2') format('woff2'),
       url('../fonts/sharepass-webfont.woff') format('woff');*/ url("../fonts/SharePass.otf")
    format("opentype");
  font-weight: bold;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Muli", "Helvetica", "Arial", "sans-serif";
  /*font-family: 'sharepass_regular';*/
  background-color: rgba(0, 0, 0);
}

.sharepass-font {
  font-family: "sharepass_regular";
}

a {
  color: #007bff;
  transition: all 0.35s;
}

a:hover,
a:focus {
  color: #0056b3;
}

hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

hr.light {
  border-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "sharepass_regular";
  /*font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';*/
  font-weight: 200;
  letter-spacing: 1px;
  text-transform: uppercase;
}

p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

section {
  padding: 20px 0;
}

section h2 {
  font-size: calc(23px + 1vw);
  color: #eee;
}

.lh0 {
  line-height: 0;
}

#mainNav {
  border-color: rgba(34, 34, 34, 0.05);
  background-color: #000;
  transition: all 0.35s;
  font-family: "sharepass_regular";
  /*font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';*/
  font-weight: 200;
  letter-spacing: 1px;
}

#mainNav .navbar-brand {
  color: #fff;
  font-family: "sharepass_regular";
  /*font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';*/
  font-weight: 600;
  letter-spacing: 1px;
}

#mainNav .navbar-brand:hover,
#mainNav .navbar-brand:focus {
  color: #339af0;
}

#mainNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #fff;
}

#mainNav .navbar-nav > li > a {
  font-size: 20px;
  font-family: "sharepass_regular";
  /*font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';*/
  letter-spacing: 2px;
  text-transform: uppercase;
}

#mainNav .navbar-nav > li > a.active {
  color: #339af0 !important;
  background-color: transparent;
}

#mainNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#mainNav .navbar-nav > li > a,
#mainNav .navbar-nav > li > a:focus {
  color: #fff;
}

#mainNav .navbar-nav > li > a:hover,
#mainNav .navbar-nav > li > a:focus:hover {
  color: #339af0;
}

.main-intro {
  font-family: "sharepass_regular";
  margin-bottom: 30px;
  font-size: calc(27px + 0.7vw);
  text-align: left;
  font-weight: bold;
}

/*#generate-video {
  max-width: 100%;
  height: auto;
}*/

#linkGeneratedWrapper {
  /*margin-top: 50px;
  border: 0.5px green solid;
  padding: 15px;*/
  display: none;
}

#errorWrapper {
  /*margin-top: 50px;
  border: 0.5px #a83e32 solid;
  padding: 15px;*/
  display: none;
  background: rgb(219, 68, 55, 0.5);
  padding: 20px 0;
  border-radius: 10px;
}

#successWrapper {
  /*margin-top: 50px;
  border: 0.5px #a83e32 solid;
  padding: 15px;*/
  display: none;
  background: rgb(136, 232, 179, 0.5);
  padding: 20px 0;
  border-radius: 10px;
}

.btn-xs {
  font-size: 0.75rem;
}

#generateLinkBtn {
  margin-left: -65px;
}

.menu-secret-options {
  display: inline-block;
  margin: 0 10px;
}

.menu-secret-options button {
  line-height: 1px;
}

.secret-input {
  display: inline-block;
  width: calc(100% - 70px);
}

.secret-options {
  background: rgb(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  width: calc(100% - 70px);
}

.secret-options label {
  font-weight: bold;
}

/**
BEGIN: Radio button effect
**/
.radio-button {
  display: inline-block;
  width: 90px;
  text-align: center;
}

.radio-button input[type="radio"] {
  display: none;
}

#ttlWrapper {
  width: 80px;
  padding-left: 15px;
  margin-right: 3px;
}

#ttlTypeWrapper {
  width: 116px;
}

.radio-button label {
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  background-color: #dadada;
  padding: 10px;
  margin: 0;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
  transition: background 0.5s;
}

.radio-button input[type="radio"]:checked + label {
  background-color: #007bff;
  color: #fafaf4;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
  transition: background 0.5s;
}

/* Foundation overrides */
/*.row {
  padding: 0 20px;
}*/

input[type="checkbox"] + label,
input[type="radio"] + label {
  margin: 0;
}
/******************************************/

/**
BEGIN: Switcher styling
**/

.can-toggle {
  position: relative;
}
.can-toggle *,
.can-toggle *:before,
.can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #777;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #5e5e5e;
}
.can-toggle input[type="checkbox"]:hover ~ label {
  color: #6a6a6a;
}
.can-toggle input[type="checkbox"]:checked ~ label:hover {
  color: #55bc49;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #70c767;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #4fb743;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
  background-color: #5fc054;
}
.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  color: #47a43d;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
}
.can-toggle label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #848484;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #777;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  transform: translate3d(76px, 0, 0);
  width: 80px;
}
.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 0 0 160px;
  border-radius: 4px;
}
.can-toggle label .can-toggle__switch:before {
  left: 70px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
}
.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 75px;
  line-height: 32px;
  font-size: 12px;
}
.can-toggle label .can-toggle__switch:hover:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch:after {
  transform: translate3d(44px, 0, 0);
}
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small label {
  font-size: 13px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch {
  height: 28px;
  flex: 0 0 90px;
  border-radius: 2px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
  left: 45px;
  font-size: 10px;
  line-height: 28px;
  width: 45px;
  padding: 0 12px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
  top: 1px;
  left: 1px;
  border-radius: 1px;
  width: 44px;
  line-height: 26px;
  font-size: 10px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-large
  input[type="checkbox"]:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.can-toggle--size-large
  input[type="checkbox"]:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-large
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch:after {
  transform: translate3d(78px, 0, 0);
}
.can-toggle.can-toggle--size-large
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.can-toggle--size-large
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-large label {
  font-size: 14px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch {
  height: 50px;
  flex: 0 0 160px;
  border-radius: 4px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:before {
  left: 80px;
  font-size: 14px;
  line-height: 50px;
  width: 80px;
  padding: 0 12px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 78px;
  line-height: 46px;
  font-size: 14px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:hover:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.can-toggle.secret-type-switcher input[type="checkbox"][disabled] ~ label {
  color: rgba(181, 62, 116, 0.5);
}
.can-toggle.secret-type-switcher
  input[type="checkbox"]:focus
  ~ label
  .can-toggle__switch,
.can-toggle.secret-type-switcher
  input[type="checkbox"]:hover
  ~ label
  .can-toggle__switch {
  background-color: #007bff;
}
.can-toggle.secret-type-switcher
  input[type="checkbox"]:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.secret-type-switcher
  input[type="checkbox"]:hover
  ~ label
  .can-toggle__switch:after {
  color: #0063ce;
}
.can-toggle.secret-type-switcher input[type="checkbox"]:hover ~ label {
  color: #0272eb;
}
.can-toggle.secret-type-switcher input[type="checkbox"]:checked ~ label:hover {
  color: #0063ce;
}
.can-toggle.secret-type-switcher
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch {
  background-color: #007bff;
}
.can-toggle.secret-type-switcher
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch:after {
  color: #0272eb;
}
.can-toggle.secret-type-switcher
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch,
.can-toggle.secret-type-switcher
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch {
  background-color: #007bff;
}
.can-toggle.secret-type-switcher
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.secret-type-switcher
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  color: #0063ce;
}
.can-toggle.secret-type-switcher label .can-toggle__label-text {
  flex: 1;
}
.can-toggle.secret-type-switcher label .can-toggle__switch {
  transition: background-color 0.3s ease-in-out;
  background: #0063ce;
}
.can-toggle.secret-type-switcher label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.6);
}
.can-toggle.secret-type-switcher label .can-toggle__switch:after {
  transition: transform 0.3s ease-in-out;
  color: #007bff;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"][disabled] ~ label {
  color: rgba(68, 68, 68, 0.5);
}
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:focus
  ~ label
  .can-toggle__switch,
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:hover
  ~ label
  .can-toggle__switch {
  background-color: #444;
}
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:hover
  ~ label
  .can-toggle__switch:after {
  color: #2b2b2b;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:hover ~ label {
  color: #373737;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label:hover {
  color: #62b125;
}
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch {
  background-color: #75d32d;
}
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch:after {
  color: #5da924;
}
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch,
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch {
  background-color: #69be28;
}
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  color: #52941f;
}
.can-toggle.demo-rebrand-2 label .can-toggle__label-text {
  flex: 1;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  background: #515151;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.7);
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  color: #444;
}
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch:after {
  transform: translate3d(58px, 0, 0);
}
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.demo-rebrand-2
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.can-toggle.demo-rebrand-2 label {
  font-size: 13px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  height: 60px;
  flex: 0 0 120px;
  border-radius: 60px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  left: 60px;
  font-size: 13px;
  line-height: 60px;
  width: 60px;
  padding: 0 12px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 30px;
  width: 58px;
  line-height: 56px;
  font-size: 13px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:hover:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

/******************************************/

/**
BEGIN: Checkbox style
**/
/* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0; /*calc(50% - 13px);*/
  height: 25px;
  width: 25px;
  background-color: #eee;
  margin-top: 5px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #007bff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The container */
.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background-color: #007bff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
/**
END: Checkbox style
**/

@media (min-width: 992px) {
  .signin-nav-button {
    margin-left: 8px;
  }

  #mainNav {
    border-color: transparent;
    background-color: transparent;
  }
  #mainNav .navbar-brand {
    color: fade(white, 70%);
  }
  #mainNav .navbar-brand:hover,
  #mainNav .navbar-brand:focus {
    color: white;
  }
  #mainNav .navbar-nav > li > a,
  #mainNav .navbar-nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-nav > li > a:hover,
  #mainNav .navbar-nav > li > a:focus:hover {
    color: white;
  }
  #mainNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    background-color: #000;
  }
  #mainNav.navbar-shrink .navbar-brand {
    color: #fff;
  }
  #mainNav.navbar-shrink .navbar-brand:hover,
  #mainNav.navbar-shrink .navbar-brand:focus {
    color: #339af0;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #fff;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a:hover,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #339af0;
  }
}

.btn-outline-primary,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 123, 255);
  border-color: rgb(0, 123, 255);
}

header.masthead {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: white;
  height: 100vh;
  /*background: url("../img/bg-pattern.png"), url("../img/bg.jpg");/*,linear-gradient(to left, #201a24, #3c015a);*/

  /*background: url("../img/bg-pattern.png"), url("../img/bg.jpg");/*,linear-gradient(to left, #201a24, #3c015a);*/

  /*background-repeat: repeat,
      no-repeat,
      no-repeat;*/
}

.ymt-masthead {
  padding-bottom: 45px !important;
  color: #fff;
}

.mid-logo-full {
  width: 20%;
  min-width: 200px;
  margin-top: 20px;
}

/* Indicator */

/************************/
.group-password {
  margin-bottom: 0px !important;
}

.group-indicator {
  /*margin-bottom: 0px !important;*/
  height: 40px !important;
  padding-top: 6px;
}

.indicator {
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  display: none;
}

.indicator span {
  width: 100%;
  height: 100%;
  background-color: #d3d3d3;
  border-radius: 3px;
  position: relative;
}

.indicator span:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.indicator span.medium {
  margin: 0 3px;
}

.indicator span.weak-active:before {
  background-color: #ff4757;
}

.indicator span.medium-active:before {
  background-color: #f5d142;
}

.indicator span.strong-active:before {
  background-color: #23ad5c;
}

#indicator-text {
  font-weight: bolder;
  margin-bottom: -10px;
  display: none;
  font-size: calc(12px + 0.7vw);
  line-height: calc(16px + 1vw);
}

#indicator-text small {
  font-weight: 600 !important;
}

#indicator-text.weak {
  color: #ff4757;
}

#indicator-text.medium {
  color: #f5d142;
}

#indicator-text.strong {
  color: #23ad5c;
}

/* END: Indicator */

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  top: 0px;
  left: 0px;
  background-color: rgb(0, 0, 0);
  z-index: 1000;
}

header.masthead .header-content {
  max-width: 500px;
  margin-bottom: 100px;
  text-align: center;
}

header.masthead .header-content h1 {
  font-size: 30px;
}

header.masthead .device-container {
  max-width: 325px;
  margin-right: auto;
  margin-left: auto;
}

header.masthead .device-container .screen img {
  border-radius: 3px;
}

@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 775px;
    padding-top: 0;
    padding-bottom: 0;
  }
  header.masthead .header-content {
    margin-bottom: 0;
    text-align: left;
  }
  header.masthead .header-content h1 {
    font-size: 50px;
  }
  header.masthead .device-container {
    max-width: 325px;
  }
  .arrows-container {
    margin-bottom: 70px;
  }
}

section.download {
  position: relative;
  padding: 150px 0;
}

section.download h2 {
  font-size: 50px;
  margin-top: 0;
}

section.download .badges .badge-link {
  display: block;
  margin-bottom: 25px;
}

section.download .badges .badge-link:last-child {
  margin-bottom: 0;
}

section.download .badges .badge-link img {
  height: 60px;
}

.password-empty-error {
  box-shadow: 0px 0px 30px red;
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
}

#messageInputWrapper.password-empty-error {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

textarea.error,
input.error {
  box-shadow: 0px 0px 30px red;
}

.text-box-style {
  background-color: rgb(0, 0, 0, 0.5);
  padding: 30px;
}

@media (min-width: 768px) {
  section.download .badges .badge-link {
    display: inline-block;
    margin-bottom: 0;
  }

  section.download h2 {
    font-size: 70px;
  }

  .w100 {
    width: 100% !important;
  }
}

.h3,
h3 {
  font-size: calc(17px + 1vw);
}

.d-only-m768 {
  display: none;
}

.count-text {
  font-size: calc(17px + 1vw);
  line-height: calc(17px + 1vw);
}

.logo {
  width: 64px;
}

#response-inner-container {
  padding: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.progress-bar-container {
  border-radius: 0 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  --progress: 100%;
  -webkit-transition: --progress 0.8s ease;
  -moz-transition: --progress 0.8s ease;
  -o-transition: --progress 0.8s ease;
  transition: --progress 0.8s ease;
  border-bottom: 4px solid;
  border-image: linear-gradient(
      90deg,
      green var(--progress),
      #d3d3d3 0%,
      #d3d3d3 100%
    )
    1;
}

#response-outer-container,
.free-form {
  z-index: 990;
  margin-top: 200px;
}

.free-form {
  max-width: 500px;
}

@media (max-width: 767px) {
  .mmt-3 {
    margin-top: 3rem;
  }

  .mmt-4 {
    margin-top: 4rem;
  }

  .mmt-5 {
    margin-top: 5rem;
  }
}

.features-mobile {
  display: none;
}

@media (max-width: 768px) {
  .ymt-masthead {
    padding-top: 100px !important;
  }

  .radio-button {
    width: 87.27px !important;
  }

  #generate-video {
    margin-top: 65px;
  }

  .features-desktop {
    display: none;
  }

  .features-mobile {
    display: block;
  }

  section.features .feature-img-container {
    height: 170px !important;
  }

  section.features .feature-item img {
    width: 150px !important;
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: calc(50% - 75px) !important;
  }

  section.features .feature-item img:hover {
    width: 150px !important;
    left: calc(50% - 75px) !important;
  }

  #response-inner-container {
    padding: 10px;
  }

  .video-explainer {
    width: 100%;
    height: 100vh;
    max-height: 300px !important;
  }

  .free-form {
    margin-top: 100px;
  }

  #response-outer-container {
    margin-top: 0px;
  }

  section h2 {
    font-size: calc(18px + 1vw);
    color: #eee;
  }

  .supported-platforms-container {
    margin-bottom: 40px;
  }

  .count-text,
  .h3,
  h3,
  section.features .feature-item h3 {
    font-size: calc(14px + 1vw);
  }
  body,
  p {
    font-size: calc(10px + 1vw);
  }

  .d-none-m768 {
    display: none;
  }

  .logo {
    width: 32px;
  }

  /*.slider-panel-inner{
    padding-top: 70px;
  }*/

  .d-only-m768 {
    display: block;
  }

  .text-box-style {
    padding: 10px;
  }

  .stats-icons:hover {
    font-size: calc(30px + 3vw) !important;
  }

  .stats-icons-container {
    height: calc(30px + 3vw) !important;
  }

  #successWrapper > div > div {
    font-size: 3vw;
  }
}

@media (max-height: 620px) {
  .free-form {
    margin-top: 0px;
  }
}

.about-business {
  background: none;
}

.page-container .section-heading,
section.features .section-heading {
  margin-bottom: 20px;
}

section.features .section-heading h2 {
  margin-top: 0;
}

section.features .section-heading p {
  margin-bottom: 0;
}

section.features .device-container,
section.features .feature-item {
  max-width: 325px;
  margin: 0 auto;
}

section.features .device-container {
  margin-bottom: 100px;
}

@media (min-width: 992px) {
  section.features .device-container {
    margin-bottom: 0;
  }
}

section.features .feature-item {
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
}

section.features .feature-item p {
  /*text-transform: uppercase;*/
  color: #aaa !important;
}

section.features .feature-item h3 {
  text-transform: uppercase;
  font-size: calc(17px + 1vw);
  color: #ddd !important;
}

section.features .feature-item i {
  font-size: 80px;
  display: block;
  margin-bottom: 15px;
  background: linear-gradient(to left, #154266, #339af0);
  /*background: linear-gradient(to left, #7b4397, #dc2430);*/
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

section.features .feature-img-container {
  height: 220px;
}

section.features .feature-item img {
  width: 200px;
  transition: all 0.2s ease-in-out;
  position: absolute;
  left: calc(50% - 100px);
}

section.features .feature-item img:hover {
  width: 220px;
  left: calc(50% - 110px);
}

/*section.cta {
  position: relative;
  padding: 250px 0;
  background-image: url("../img/bg-cta.jpg");
  background-position: center;
  background-size: cover;
}*/

section.cta .cta-content {
  position: relative;
  z-index: 1;
}

section.cta .cta-content h2 {
  font-size: 50px;
  max-width: 450px;
  margin-top: 0;
  margin-bottom: 25px;
  color: white;
}

@media (min-width: 768px) {
  section.cta .cta-content h2 {
    font-size: 80px;
  }
  .extra-margin-menu {
    margin-left: 50px;
  }
}

section.cta .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

section.contact {
  text-align: center;
}

section.contact h2 {
  margin-top: 0;
  margin-bottom: 25px;
}

section.contact h2 i {
  color: #dd4b39;
}

section.contact ul.list-social {
  margin-bottom: 0;
}

section.contact ul.list-social li a {
  font-size: 40px;
  line-height: 80px;
  display: block;
  width: 80px;
  height: 80px;
  color: white;
  border-radius: 100%;
}

section.contact ul.list-social li.social-twitter a {
  background-color: #1da1f2;
}

section.contact ul.list-social li.social-twitter a:hover {
  background-color: #0d95e8;
}

section.contact ul.list-social li.social-facebook a {
  background-color: #3b5998;
}

section.contact ul.list-social li.social-facebook a:hover {
  background-color: #344e86;
}

section.contact ul.list-social li.social-google-plus a {
  background-color: #dd4b39;
}

section.contact ul.list-social li.social-google-plus a:hover {
  background-color: #d73925;
}

.white-background-frame {
  background-color: #fff;
}

footer {
  padding: 25px 0;
  text-align: center;
  /*color: rgba(255, 255, 255, 0.3);
  background-color: #222222;*/
  color: #222222;
  background-color: #fff;
}

footer.dark-footer {
  color: rgba(255, 255, 255, 0.5);
  background-color: #222222;
}

footer p {
  font-size: 12px;
  margin: 0;
}

footer ul {
  margin-bottom: 0;
}

footer ul li a {
  font-size: 12px;
  /*color: rgba(255, 255, 255, 0.3);*/
  color: #222222;
}

footer.dark-footer ul li a {
  color: rgba(255, 255, 255, 0.5);
}


footer ul li a:hover,
footer ul li a:focus,
footer ul li a:active,
footer ul li a.active {
  text-decoration: none;
}

/*.bg-primary {
  background: #007bff;
  background: linear-gradient(#007bff, #fdc539);
}*/

.text-primary {
  color: #007bff;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.btn-outline {
  color: white;
  border: 1px solid;
  border-color: white;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active {
  color: white;
  border-color: #007bff;
  background-color: #007bff;
}

.btn {
  border-radius: 300px;
  font-family: "sharepass_regular";
  /*font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';*/
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn-xl {
  font-size: 11px;
  padding: 15px 45px;
}

.loaderDisplay {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blockPage {
  border: none !important;
  background-color: transparent !important;
  z-index: 1032 !important;
}

.blockOverlay {
  z-index: 1031 !important;
}

/*.bg-primary {
  background: #000;
  background: linear-gradient(#000 , #154266);
  color: #fff;
}*/

#sharepass {
  position: relative;
  overflow: hidden;
}

/*#sharepass::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: 1;
  background-size: 50px;
  background: url(/themes/vojtasvoboda-newage/assets/img/SP_logo.png) 0 0 repeat ;
  transform: rotate(-30deg);
}*/

#sharepass .container {
  z-index: 1010;
}
section#features {
  background-color: #000;
}
/*#features {
  background-image: url(../img/bg-pattern.png);
}*/

/*#team {
  background: #111;
  background: linear-gradient(#000 , #111);

}*/

section#team {
  padding: 0;
}

#team .team-wrapper {
  padding: 100px 0;
  /*background-image: url(../img/bg-pattern.png);*/
}

#team h2 {
  color: #fff;
}

#team .card {
  width: 16rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

#team .card h3 {
  font-size: calc(17px + 1vw) !important;
}

.g-recaptcha {
  display: inline-block;
}

.m-b-lg {
  margin-bottom: 60px !important;
}

.btn-xl {
  font-size: 20px;
}

/*#contact {
  background: #000;
  background: linear-gradient(#000 , #154266);
}*/

footer a:hover {
  color: #339af0;
}

.force-nav-bg {
  border-color: #000 !important;
  background-color: #000 !important;
}

.slider-panel {
  background: #000;
}

.bg-none {
  background: none !important;
}
.bg-mountains,
#sharepass.mobile-view .bg-none {
  background: url(../img/bg-dark.jpg) no-repeat center center scroll !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

/*section#features */

/*section#explainer,*/
/*header,*/
section#team,
section#sharepass,
section#contact {
  background: url("../img/bg-dark.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

section#sharepass > .bg-image {
  /*background: url('../img/bg-cube.jpg') no-repeat center center fixed;
  background-size: cover;
  filter: blur(40px);
  -webkit-filter: blur(40px);
  width: 100%;
  height: 100%;
    position:absolute; 
    top: 0; 
    right: 0; 
    z-index: -1;*/
}

#generate-video {
  /*border: 8px solid #000;
  border-radius: 50%;
  box-shadow: 0 0 10px 15px #000;
  margin-left:50px;*/
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.password-empty-error input::-webkit-input-placeholder {
  color: #f54242;
}

.password-empty-error .btn-outline-primary {
  background-color: #f54242;
  border-color: #f54242;
}

section#contact textarea,
section#contact input {
  background: rgb(200, 200, 255, 0.1);
  border: none;
  color: rgb(255, 255, 255, 1);
}

section#contact textarea::-webkit-input-placeholder,
section#contact input::-webkit-input-placeholder {
  color: rgb(255, 255, 255, 1);
}
.page-container h2 {
  color: #000;
}
.page-container {
  background: url("../img/bg-dark.jpg") no-repeat center center fixed;
  padding-bottom: 0 !important;
}

.page-container > .container > .row {
  padding: 100px 0;
  background-color: #fff !important;
}

.post-list {
  list-style-type: none;
}

/*.post-list .featured-images {
  width: 30%;
  display: inline-block;
}

.post-list .post-content-list {
  width: 65%;
  display: inline-block;
}*/
.singup-nav-button {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-width: medium;
}

.signin-nav-button {
  /*font-size: 1rem!important;
    margin-top: -5px!important;
    background-color: transparent!important;*/

  font-size: 15px !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-width: medium;
}

.reg-nav-button {
  background-color: transparent !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-width: medium;
  font-size: 15px !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  color: rgba(255, 255, 255, 0.7) !important;
  margin-left: 30px;
}

.white-color,
#mainNav .navbar-nav > li > a.btn:hover,
#mainNav .navbar-nav > li > a.btn:focus {
  color: #fff !important;
}

.counter i,
.count-text {
  color: #fff;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #007bff !important;
  border-radius: 0.25rem;
}

.tooltip-arrow {
  border-bottom-color: none !important;
}

.blue-tooltip-box .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: transparent !important;
}

.blue-tooltip-box .bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: transparent !important;
}

.blue-tooltip-box .bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: transparent !important;
  display: none;
}

.blue-tooltip-box .bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: transparent !important;
}

.expiry-tooltip-box > .tooltip-inner {
  background-color: #fff !important;
  color: #000 !important;
}

.underlined {
  text-decoration: underline;
}

.expiry-tooltip-box > .tooltip-arrow {
  background-color: #fff !important;
}

.expiry-tooltip-box .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #fff;
}
.expiry-tooltip-box .bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #fff;
}
.expiry-tooltip-box .bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff;
  display: none;
}
.expiry-tooltip-box .bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #fff;
}

/***********************************************************************/
.danger-color {
  color: #721c24 !important;
}

.danger-tooltip-box > .tooltip-inner {
  color: #721c24 !important;
  background-color: #f8d7da !important;
}

.danger-tooltip-box > .tooltip-arrow {
  background-color: #f8d7da !important;
}

.danger-tooltip-box .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #f8d7da;
}
.danger-tooltip-box .bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #f8d7da;
}
.danger-tooltip-box .bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f8d7da;
  display: none;
}
.danger-tooltip-box .bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #f8d7da;
}

/***********************************************************************/

.wb-none {
  word-break: keep-all;
}

.blue-color {
  color: #007bff !important;
}

.yellow-color {
  color: #f4b400 !important;
}

.black-color {
  color: #000;
}

.green-color {
  color: #0f9d58 !important;
}

.red-color {
  color: #db4437 !important;
}

.green-sust {
  color: #88e8b3 !important;
}

.supported-platforms-container i.status-indicator {
  line-height: calc(30px + 1vw) !important;
}

.sp-info-text {
  text-align: left;
  color: #fff;
}

.supported-platforms-container {
  background: rgba(0, 0, 0, 0.5);
  padding: 30px 0;
  color: #fff;
  border-radius: 10px;
}

.blog h3 a {
  color: #eee;
}

.blog .post-list .featured-images,
.blog .post-list .post-content-list {
  display: block !important;
}

.blog .post-list .post-content-list {
  color: #aaa;
}

.blog .post-list .post-content-list h3 {
  text-align: center;
}

.blog .post-list .featured-images {
  text-align: center;
}

.blog .post-list .featured-images img {
  height: 200px;
}

.post-list > div {
  padding: 20px;
}

.is-blog .post-list {
  margin-left: -40px;
}

.post-inner-container {
  padding: 30px;
  background: rgba(0, 0, 0, 0.5);
}

/**************** RECEIVER COUNTDOWN *****************/
/*#countdownBar {
  width: calc(100% - 84px);
  margin: auto;
  height: 3px;
  background-color: #d3d3d3;
}

#countdownBar div {
  height: 100%;
  padding: 0 10px;
  line-height: 22px; 
  width: 0;
  background-color: #0F9D58;
  box-sizing: border-box;
}

#countdownBarNumber {
  color:#0F9D58;
  text-align: center;
  font-size: calc(12px + 1vw);
}
*/
/*#secretData {
  border: none;
  --progress: 100%;
  -webkit-transition: --progress 0.8s ease;
  -moz-transition: --progress 0.8s ease;
  -o-transition: --progress 0.8s ease;
  transition: --progress 0.8s ease;
  border-bottom: 4px solid;
  border-image: linear-gradient(90deg, green var(--progress), #d3d3d3 0%, #d3d3d3 100%) 1;
}*/

/***************** ARROWS EFFECTS *******************/

.abs-ctn {
  position: absolute;
  bottom: 0px;
}

.arrows-container {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 60px;
  margin-left: calc(50% - 30px);
  z-index: 999;
}

.arrows-container div {
  margin-bottom: 5px;
}

.arrows {
  cursor: pointer;
  width: 40px;
  height: 55px;
  /*position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: 5px;*/
  margin-bottom: 5px;
}

.arrows path {
  stroke: #fff;
  fill: transparent;
  stroke-width: 3px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}
/********** fadein from bottom effect ************/

.come-in {
  transform: translateY(150px);
  animation: come-in 0.8s ease forwards;
}
.come-in:nth-child(odd) {
  animation-duration: 0.6s;
}
.already-visible {
  transform: translateY(0);
  animation: none;
}

@keyframes come-in {
  to {
    transform: translateY(0);
  }
}

/*************Fadein from sides effect*************/
.fancy-pill-left,
.fancy-pill-right {
  background: rgb(200, 200, 255, 0.1);
  padding: 40px 100px;
  margin-bottom: 40px;
}

.fancy-pill-right {
  border-top-left-radius: 100px;
  text-align: left;
  border-bottom-left-radius: 100px;
}
.fancy-pill-left {
  text-align: right;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.fancy-pill-right p,
.fancy-pill-left p {
  color: #fff;
}

.doing {
  transform: rotate(-35deg);
  display: block;
  position: absolute;
  top: 0;
  left: -90px;
  margin-top: 25px;
  text-align: center;
  background-color: #15748b;
  width: 300px;
  color: #fff;
  padding: 5px 0px;
}

/*.will-fadeIn {
	display: block;
	width: 100%;
	max-width: 640px;
	margin: 20px auto;
	height: 250px;
	background-color: #b7f7f7;
	padding: 10px;
  }
  .will-fadeIn:nth-child(odd) {
	background: #ffeded;
  }
  .will-fadeIn:first-child {
	height: auto;
	background-color: #edffff;
  }*/

.fadeInRight {
  -webkit-animation: fadeInRight 0.5s ease 0.4s both;
  -moz-animation: fadeInRight 0.5s ease 0.4s both;
  -ms-animation: fadeInRight 0.5s ease 0.4s both;
  -o-animation: fadeInRight 0.5s ease 0.4s both;
  animation: fadeInRight 0.5s ease 0.4s both;
}
@media (prefers-reduced-motion) {
  .fadeInRight .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}

.fadeInLeft {
  -webkit-animation: fadeInLeft 0.5s ease 0.4s both;
  -moz-animation: fadeInLeft 0.5s ease 0.4s both;
  -ms-animation: fadeInLeft 0.5s ease 0.4s both;
  -o-animation: fadeInLeft 0.5s ease 0.4s both;
  animation: fadeInLeft 0.5s ease 0.4s both;
}
@media (prefers-reduced-motion) {
  .fadeInLeft .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-ms-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-ms-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/****************************************/
.steps-container > div {
  /*padding:10px;*/
}

#sharepass-explainer {
  margin-bottom: 50px;
}

.steps-container > div > div {
  color: #fff !important;
  /*background: rgb(200,200,255,0.1);*/
  /*padding:30px;*/
}

section#sharepass {
  padding: 0 !important;
  /*background: linear-gradient(0.25turn, #040d21 40%, #007bff 50%, #040d21 60%);
    background-size: cover;
    background-position: 50% 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;*/
  /*border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;*/
}

section#sharepass h2,
section#sharepass h3 {
  color: #fff;
}

.statistics-home {
  /*margin:  0 0 40px 0;/*40px 40px 80px 40px;*/
  /*padding: 40px 0;
    background: rgb(0,0,0);*/
}

#sharepass {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

#sharepass.mobile-view {
  height: auto !important;
}

#sharepassSlider {
  height: 100%;
  width: 100vw;
}
/*#sharepassSlider .slider-panel {
    height: 100vh;
    width: 100vw;
    text-align: center;
  }*/

/*#sharepassSlider {
    
		width: 600vw; /* to contain 6 panels, each with 100% of window width 
		height: 100%;
	}*/

#sharepass.mobile-view #sharepassSlider {
  width: 100vw !important;
}

#how-to-list {
  text-align: left;
  /*font-size: calc(23px + 1vw);*/
  list-style: none;
  color: white;
  line-height: 4rem;
  background-color: rgb(0, 0, 0, 0.5);
  padding: 30px;
}

.slider-panel {
  height: 100vh !important;
  /*padding-top:105px;*/
  width: 100vw !important;
  float: left;
}

.mobile-view .slider-panel {
  /*height: auto!important;*/
}

.slider-panel-inner-wrapper {
  display: table-cell;
  width: 100vw;
  /*height: calc(100vh - 105px);*/
  height: 100vh;
  vertical-align: middle;
  text-align: center;
}

.mobile-view .slider-panel-inner-wrapper {
  height: auto;
  padding: 40px 0;
}

.slider-panel-inner {
  /*padding-top: 105px;*/
  display: inline-block;
  width: 75%;
  /*height: 75%;*/
}

#sharepass.mobile-view .slider-panel {
  float: none !important;
}

#sharepass.mobile-view {
  background: none !important;
}

.slide-icon-text {
  font-size: calc(17px + 1vw);
  margin-top: 20px;
}

.stats-icons-container {
  height: calc(60px + 3vw);
}

.stats-icons {
  transition: all 0.2s ease-in-out;
  font-size: calc(30px + 3vw);
}

.stats-icons:hover {
  font-size: calc(60px + 3vw);
}

.page-container > .container > .row {
  min-height: calc(100vh - 112px);
}

.page-container ul.post-list > li {
  margin-top: 40px;
  border-top: 1px solid #ccc;
  padding-top: 40px;
}

.page-container ul.post-list > li:first-child {
  border-top: none;
}

.heartbeat {
  animation: beat 0.25s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.4);
  }
}

.class-error-wrapper {
  background: rgb(219, 68, 55, 0.5) !important;
}

/*************************************************************************************/

#jquery-script-menu {
  position: absolute;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  border-top: 5px solid #316594;
  background: #fff;
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  z-index: 999999;
  padding: 10px 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.jquery-script-center {
  max-width: 960px;
  margin: 0 auto;
}
.jquery-script-center ul {
  width: 212px;
  float: left;
  line-height: 45px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.jquery-script-center a {
  text-decoration: none;
}
.jquery-script-ads {
  max-width: 728px;
  height: 90px;
  float: right;
}
.jquery-script-clear {
  clear: both;
  height: 0;
}
#carbonads {
  display: block;
  overflow: hidden;
  max-width: 728px;
  position: relative;
  font-size: 22px;
  box-sizing: content-box;
}
#carbonads > span {
  display: block;
}
#carbonads a {
  color: #4078c0;
  text-decoration: none;
}
#carbonads a:hover {
  color: #3664a3;
}
.carbon-wrap {
  display: flex;
  align-items: center;
}
.carbon-img {
  display: block;
  margin: 0;
  line-height: 1;
}
.carbon-img img {
  display: block;
  height: 90px;
  width: auto;
}
.carbon-text {
  display: block;
  padding: 0 1em;
  line-height: 1.35;
  text-align: left;
}
.carbon-poweredby {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px 10px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 8px;
  border-top-left-radius: 4px;
  line-height: 1;
  color: #aaa !important;
}
@media only screen and (min-width: 320px) and (max-width: 759px) {
  .carbon-text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1023px) {
  .jquery-script-ads {
    display: none;
  }
}

.popup a {
  color: #007bff;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.popup a:hover,
.popup a:active,
.popup a:focus {
  color: #007bff;
  outline: none;
  text-decoration: none;
}

/*::-webkit-selection {
  color: #fff;
  background: #52d3aa;
}

::-moz-selection {
  color: #fff;
  background: #52d3aa;
}

::selection {
  color: #fff;
  background: #52d3aa;
}*/

#fh5co-wrap {
  position: relative;
  background: #fff;
  z-index: 2;
  margin-bottom: 450px;
}
@media screen and (max-width: 992px) {
  #fh5co-wrap {
    margin-bottom: auto;
  }
}

#fh5co-footer {
  padding-top: 4em;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 450px;
  background: #333333;
  font-size: 18px;
}
@media screen and (max-width: 992px) {
  #fh5co-footer {
    height: inherit;
    position: relative;
    padding: 5em 0;
  }

  .reg-nav-button {
    display: inline-block;
    width: 50%;
    min-width: 300px;
    margin: 0 0 15px 0;
  }

  .signin-nav-button,
  .reg-nav-button {
    display: inline-block;
    width: 50%;
    min-width: 300px;
  }

  .navbar-nav {
    text-align: center;
    height: calc(100vh - 90px);
  }
}
#fh5co-footer .fh5co-social li {
  display: block;
}
#fh5co-footer p {
  line-height: 1.5;
}
#fh5co-footer h3 {
  color: #fff;
  font-size: 20px;
}
#fh5co-footer a {
  color: rgba(255, 255, 255, 0.7);
}
#fh5co-footer a:hover {
  color: #52d3aa;
}
#fh5co-footer ul {
  padding: 0;
  margin: 0;
}
#fh5co-footer ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}
#fh5co-footer .fh5co-copyright {
  padding-top: 3em;
}
#fh5co-footer .fh5co-copyright small {
  display: block;
}

#fh5co-header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}
#fh5co-header nav {
  text-align: center;
}
#fh5co-header nav ul {
  padding: 0;
  margin: 0;
}
#fh5co-header nav li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
}
#fh5co-header nav li a {
  padding: 20px;
  color: rgba(255, 255, 255, 0.7);
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
}
#fh5co-header nav li a span {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 2px solid transparent;
  display: block;
}
@media screen and (max-width: 768px) {
  #fh5co-header nav li a {
    padding: 10px 2px;
  }
}
#fh5co-header nav li a:hover {
  color: white;
}
#fh5co-header nav li a:hover span {
  border-bottom: 2px solid #fff;
}
#fh5co-header nav li.fh5co-active a {
  color: #fff;
}
#fh5co-header nav li.fh5co-active a span {
  border-bottom: 2px solid #fff;
}

.fh5co-table,
.fh5co-table-cell,
.fh5co-hero,
.fh5co-parallax {
  height: 670px;
}

.fh5co-hero,
.fh5co-parallax {
  background-color: #555;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}
.fh5co-hero .overlay,
.fh5co-parallax .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.2;
}

@media screen and (max-width: 768px) {
  .fh5co-hero,
  .fh5co-parallax {
    height: inherit;
    padding: 4em 0;
  }
}
.fh5co-hero .fh5co-intro h1,
.fh5co-parallax .fh5co-intro h1 {
  color: #fff;
  font-size: 70px;
  font-weight: 300;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .fh5co-hero .fh5co-intro h1,
  .fh5co-parallax .fh5co-intro h1 {
    font-size: 30px;
  }
}
.fh5co-hero .fh5co-intro p,
.fh5co-parallax .fh5co-intro p {
  color: #fff;
}
.fh5co-hero .fh5co-intro p a,
.fh5co-parallax .fh5co-intro p a {
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
.fh5co-hero .fh5co-intro p a:hover,
.fh5co-parallax .fh5co-intro p a:hover {
  color: #fff;
}

.fh5co-table {
  display: table;
}

.fh5co-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.fh5co-section {
  padding: 5em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-section {
    padding: 3em 0;
  }
}

.fh5co-feature {
  text-align: left;
  width: 100%;
  float: left;
  padding: 20px;
}
.fh5co-feature .fh5co-icon {
  float: left;
  width: 10%;
  display: block;
  margin-top: 5px;
}
.fh5co-feature .fh5co-icon i {
  color: #52d3aa;
  font-size: 70px;
}
@media screen and (max-width: 1200px) {
  .fh5co-feature .fh5co-icon i {
    font-size: 40px;
  }
}
.fh5co-feature.fh5co-feature-sm .fh5co-icon i {
  color: #52d3aa;
  font-size: 28px;
}
@media screen and (max-width: 1200px) {
  .fh5co-feature.fh5co-feature-sm .fh5co-icon i {
    font-size: 28px;
  }
}
.fh5co-feature .fh5co-text {
  float: right;
  width: 80%;
}
@media screen and (max-width: 768px) {
  .fh5co-feature .fh5co-text {
    width: 82%;
  }
}
@media screen and (max-width: 480px) {
  .fh5co-feature .fh5co-text {
    width: 72%;
  }
}
.fh5co-feature .fh5co-text h2,
.fh5co-feature .fh5co-text h3 {
  margin: 0;
  padding: 0;
}
.fh5co-feature .fh5co-text h3 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.fh5co-social {
  padding: 0;
  margin: 0;
}
.fh5co-social li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.fh5co-social li a span {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  padding-left: 10px;
}

.popup .form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  height: 54px;
  font-size: 18px;
  font-weight: 300;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

.form-control:active,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #52d3aa;
}

.fh5co-check {
  padding: 0 0 0 0;
  margin: 0 0 1.5em 0;
}
.fh5co-check li {
  padding: 0 0 0 40px;
  margin: 0;
  list-style: none;
  position: relative;
}
.fh5co-check li:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 0.3em;
  content: "\f00c";
  color: #52d3aa;
}

.fh5co-staff img {
  margin-bottom: 1em;
}
.fh5co-staff h3 {
  margin: 0;
}
.fh5co-staff h4 {
  margin: 0 0 20px 0;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.4);
}

#map {
  width: 100%;
  height: 700px;
}
@media screen and (max-width: 768px) {
  #map {
    height: 200px;
  }
}

@media screen and (max-width: 480px) {
  .col-xxs-12 {
    float: none;
    width: 100%;
  }
}

.row-bottom-padded-lg {
  padding-bottom: 7em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-lg {
    padding-bottom: 2em;
  }
}

.row-bottom-padded-md {
  padding-bottom: 4em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-md {
    padding-bottom: 2em;
  }
}

.row-bottom-padded-sm {
  padding-bottom: 2em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-sm {
    padding-bottom: 2em;
  }
}

/* Popup */
@charset "UTF-8";
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

/* Popup */

.c-ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
}

.c-ripple-circle-accept {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(47, 208, 74, 0.18);
}
.c-ripple.is-active .c-ripple-circle-accept {
  animation: a-ripple 0.4s ease-in;
}

.c-ripple-circle-enregistre,
.c-ripple-circle-configure {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(93, 177, 251, 0.4);
}
.c-ripple.is-active .c-ripple-circle-enregistre,
.c-ripple.is-active .c-ripple-circle-configure {
  animation: a-ripple 0.4s ease-in;
}

@keyframes a-ripple {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: 0;
  }
}
.switch {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 60px;
  /*height: 31px;*/
  border-radius: 50px;
  background-color: white;
  padding: 5px;
  box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16),
    0 3px 6px rgba(255, 255, 255, 0.23);
  cursor: pointer;
}
.switch .circle {
  width: 15px;
  height: 15px;
  background-color: #c3c3c3;
  border-radius: 50%;
  transform: translateX(1.7rem);
  background-color: #88e8b3;
}

.move-circle-right {
  animation: 0.5s moveCircleRight cubic-bezier(0.87, -0.41, 0.19, 1.44) forwards;
  transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
}

.move-circle-left {
  animation: 0.5s moveCircleLeft cubic-bezier(0.87, 0.41, 0.19, 1.44) forwards;
  transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
}

@keyframes moveCircleRight {
  0% {
    transform: translateX(0);
    background-color: #c3c3c3;
  }
  100% {
    transform: translateX(1.7rem);
    background-color: #62d7b2;
  }
}
@keyframes moveCircleLeft {
  0% {
    transform: translateX(1.7rem);
    background-color: #62d7b2;
  }
  100% {
    transform: translateX(0);
    background-color: #c3c3c3;
  }
}
.background-popup {
  position: fixed;
  background-color: black;
  width: 500%;
  height: 500%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 6;
  transition-duration: 0.3s;
}

.popup {
  display: none;
  position: fixed;
  /*top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  max-width: 95%;*/
  bottom: 0;
  left: 0;
  width: 100%;
  color: #d1d1d1;
  background: #333;
  padding: 10px;
  /*border-radius: 12px;*/
  font-size: 1.05em;
  box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16),
    0 3px 6px rgba(255, 255, 255, 0.23);
  word-wrap: break-word;
  text-align: justify;
  z-index: 7000;
}

.popup section {
  margin: 0;
  padding: 10px;
}

.popup #contentPopupConsent {
  width: 70%;
  float: left;
}

.popup #contentPopupConsent:hover,
.popup #configureSection:hover {
  box-shadow: 0 0 0 transparent;
}
.popup .content-popup-theme {
  text-align: left;
}
.popup .content-popup-theme:hover {
  box-shadow: 0 0 0 transparent;
}
.popup .content-popup-theme p {
  padding-right: 5px;
  padding-left: 5px;
  display: block;
  text-align: center;
}
.popup .buttonOk {
  color: black;
  background: transparent;
  padding: 9px 0.57em;
  -webkit-appearance: none;
  position: relative;
  display: block;
  margin: 0;
  vertical-align: middle;
  overflow: visible;
  font-size: 14px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
  border: 0;
  border-radius: 7px;
  user-select: none;
  transition: all 0.2s ease;
  outline: 0;
  cursor: pointer;
  margin-right: 2%;
  margin-left: auto;
}
.popup .buttonOk:hover {
  background: rgba(0, 0, 0, 0.08);
}
.popup p {
  padding-right: 5px;
  padding-left: 5px;
  font-size: 1rem;
}
.popup .choice-container-buttons {
  display: flex;
  justify-content: space-around;
  /*margin-right: 20%;
  margin-left: 20%;*/
  margin-top: 20px;
  margin-bottom: 20px;
  width: 20%;
  float: left;
}

.popup .choice-container-buttons .c-button {
  padding: 9px 0.57em;
  -webkit-appearance: none;
  position: relative;
  /*display: inline-block;*/
  display: block;
  margin: 0;
  /*vertical-align: middle;*/
  overflow: visible;
  font-size: 14px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
  background: transparent;
  border: 0;
  border-radius: 1px;
  user-select: none;
  transition: all 0.2s ease;
  background: transparent;
  outline: 0;
}
.popup .choice-container-buttons .c-button:hover,
.popup .choice-container-buttons .c-button:focus {
  text-decoration: none;
}
.popup .choice-container-buttons .c-button:not(:disabled) {
  cursor: pointer;
}
.popup .choice-container-buttons #accept {
  color: #88e8b3;
}
.popup .choice-container-buttons #accept:hover {
  background: #2fd04a17;
}
.popup .choice-container-buttons #configure {
  color: #4285f4;
}
.popup .choice-container-buttons #configure:hover {
  background: #f0f8ff;
}
.popup .choice-container-buttons #enregistre {
  display: none;
  color: #4285f4;
}
.popup .choice-container-buttons #enregistre:hover {
  background: #f0f8ff;
}
.popup #configureSection {
  display: none;
  width: 70%;
  float: left;
}
.popup #configureSection table th {
  color: black;
  text-align: left;
}
.popup #configureSection table .text-switch {
  padding: 0 0 0 1rem;
  font-size: 14px;
  color: #d1d1d1;
  font-weight: 100;
}
.popup #configureSection table tr {
  display: block;
  padding: 4px;
}

#contentPopupConsent p {
  margin-bottom: 0 !important;
}

#configureSection p,
#contentPopupConsent p {
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .popup .choice-container-buttons {
    display: flex;
    justify-content: space-around;
    /*margin-right: 20%;
    margin-left: 20%;*/
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    float: left;
  }

  .popup #configureSection,
  .popup #contentPopupConsent {
    width: 100%;
  }

  .popup p {
    font-size: 14px;
  }
}

.review-icon {
  margin: 0 20px 20px 20px;
  display: inline-block;
}

.video-explainer {
  width: 100%;
  height: 100vh;
  max-height: 500px;
}

#responseWrapper {
  height: 80px;
}

.no-round-btn {
  border-radius: 0;
}
/**
BEGIN: Burger menu settings, fancy
**/
#btnSecretOptions {
  width: 38px;
  padding-bottom: 0.255rem !important;
  padding-top: 0.55rem;
  letter-spacing: 0px;
  border-radius: 50% !important;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
  transition: background 0.5s;
  cursor: pointer;
}

#btnSecretOptions.open {
  background-color: transparent;
}
/*#btnSecretOptions {
  width: 45px;
  height: 30px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#btnSecretOptions span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  background: #fff;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#btnSecretOptions span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#btnSecretOptions span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#btnSecretOptions span:nth-child(1), #btnSecretOptions span:nth-child(2) {
  top: 0px;
}

#btnSecretOptions span:nth-child(3), #btnSecretOptions span:nth-child(4) {
  top: 10px;
}

#btnSecretOptions span:nth-child(5), #btnSecretOptions span:nth-child(6) {
  top: 20px;
}

#btnSecretOptions.open span:nth-child(1),#btnSecretOptions.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#btnSecretOptions.open span:nth-child(2),#btnSecretOptions.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#btnSecretOptions.open span:nth-child(1) {
  left: 5px;
  top: 8px;
}

#btnSecretOptions.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 8px;
}

#btnSecretOptions.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#btnSecretOptions.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#btnSecretOptions.open span:nth-child(5) {
  left: 5px;
  top: 20px;
}

#btnSecretOptions.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 20px;
}
*/
/*******************************************/
/*#collapseMessage .btn,
#collapseMessage textarea,
#showHideMessageBtn {
  border-radius: 0!important;
}*/

#message::placeholder,
input::placeholder,
#secret::placeholder {
  color: #ddd !important;
}

#otlWarning {
  margin-left: 40px;
  margin-top: -5px;
  display: block;
  cursor: pointer;
}

/* Floating Social Media Bar Style Starts Here */
.fl-fl {
  background: #000000;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding: 4px;
  width: 200px;
  position: fixed;
  right: -160px;
  z-index: 1000;
  font: normal normal 10px Arial;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.float-sm .fab,
.float-sm .fa {
  font-size: 20px !important;
  color: #fff !important;
  padding: 10px 0 !important;
  width: 40px !important;
  margin-left: 8px !important;
}

.fl-fl:hover {
  right: 0;
}

.fl-fl a {
  color: #fff !important;
  text-decoration: none;
  text-align: center;
  line-height: 43px !important;
  vertical-align: top !important;
}

.float-ln {
  top: calc(50% + 5px);
}

.float-fb {
  top: calc(50% + 60px);
}

.float-tw {
  top: calc(50% + 115px);
}

.float-ga {
  top: calc(50% + 170px);
}

.float-yt {
  top: calc(50% + 225px);
}
/* Floating Social Media Bar Style Ends Here */

.sweet-alert {
  color: #444 !important;
}

/****************************Checkbox styling***************************/

/*Checkboxes styles*/
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px "Open Sans", Arial, sans-serif;
  color: #ddd;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}

input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #6cc0e5;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/***************SILENT BREACH BADGE ****************************/
#silentbreach-badge {
  width: 100%;
  text-align: center;
}

#silentbreach-badge img {
  width: 50%;
  max-width: 250px;
}

/*section#certifications {
  padding: 100px 0;
}*/

.badges img {
  vertical-align: super;
}

footer .badges img {
  vertical-align: bottom !important;
}

@media (min-width: 418px) {
  .review-icon-ml-5 {
    margin-left: 3rem !important;
  }
}

@media (max-width: 417px) {
  .review-icon {
    display: block;
  }
}

.nvidia-badge {
  /*max-width: 455px;*/
  max-height: 150px;
  max-width: 100%;
}

.footer-title {
  /*color: rgb(179, 179, 179) !important;*/
  color: #222 !important;
}

.footer-separator {
  max-width: 300px;
  margin: 45px auto 45px;
  border-width: 1px;
  border-color: #ccc;
}

/* BEGIN: Progress bar styling */
.progressbar-timer {
  margin-top: 25px;
  border: solid 1px #e9ecef;
  border-radius: 5px;
}
.progressbar-timer .inner {
  height: 10px;
  animation: progressbar-timer-countdown;
  /* Placeholder, this will be updated using javascript */
  animation-duration: 40s;
  /* We stop in the end */
  animation-iteration-count: 1;
  /* Stay on pause when the animation is finished finished */
  animation-fill-mode: forwards;
  /* We start paused, we start the animation using javascript */
  animation-play-state: paused;
  /* We want a linear animation, ease-out is standard */
  animation-timing-function: linear;
  border-radius: 5px;
}
@keyframes progressbar-timer-countdown {
  0% {
    width: 100%;
    background: #5dcf78;
  }
  50% {
    width: 50%;
    background: #cfcd5d;
  }
  100% {
    width: 0%;
    background: #c00618;
  }
}
/* END: Progress bar styling */

.d-inline-block .review-icon {
  margin: 0 40px 40px 40px;
}

.yubikey-badge {
  max-height: 156px !important;
  margin: 0 !important;
  overflow: hidden;
}

.yubikey-badge img {
  max-height: 202px;
  vertical-align: middle !important;
  margin-top: -10%;
}
